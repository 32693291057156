import React, { ReactElement } from "react";
import { useStaticQuery, graphql } from "gatsby";
import classnames from "classnames";
import Img from "gatsby-image";
import { useSpring, animated } from "react-spring";

import styles from "./styles.mod.scss";
import NavBar from "../NavBar";
import { ButtonLink, ButtonWithDropDown } from "../Button";

function MainscreenSection(): ReactElement {
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const trans1 = xy.to(
    (x: number, y: number) => `translate3d(${x / -20}px,${y / -20}px,0)`
  );
  const trans2 = xy.to(
    (x: number, y: number) => `translate3d(${x / 15}px,${y / 15}px,0)`
  );
  const trans3 = xy.to(
    (x: number, y: number) => `translate3d(${x / -50}px,${y / -50}px,0)`
  );
  const trans4 = xy.to(
    (x: number, y: number) => `translate3d(${x / 20}px,${y / 20}px,0)`
  );

  const containerCn = classnames("container", styles.mainscreen__container);

  const illustrationColumnCn = classnames(
    "col-lg-5",
    "d-none",
    "d-lg-block",
    styles.illustration__column
  );

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "main-banner-background.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 985) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      lightning: file(relativePath: { eq: "lightning.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      leaves1: file(relativePath: { eq: "animate-layer-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 305) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      leaves2: file(relativePath: { eq: "animate-layer-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      leaves3: file(relativePath: { eq: "animate-layer-3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 660) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      leaves4: file(relativePath: { eq: "animate-layer-4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 401) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const illustration = (
    <div className={styles.illustration__container}>
      <div className={styles.illustration__subcontainer}>
        <Img fluid={data.background.childImageSharp.fluid} loading="eager" />
      </div>
      <animated.div className={styles.illustration__subcontainer_lightning}>
        <Img fluid={data.lightning.childImageSharp.fluid} loading="eager" />
      </animated.div>
      <animated.div
        className={styles.illustration__subcontainer__leaves1}
        style={{ transform: trans1 }}
      >
        <Img fluid={data.leaves1.childImageSharp.fluid} loading="eager" />
      </animated.div>
      <animated.div
        className={styles.illustration__subcontainer__leaves2}
        style={{ transform: trans2 }}
      >
        <Img fluid={data.leaves2.childImageSharp.fluid} loading="eager" />
      </animated.div>
      <animated.div
        className={styles.illustration__subcontainer__leaves3}
        style={{ transform: trans3 }}
      >
        <Img fluid={data.leaves3.childImageSharp.fluid} loading="eager" />
      </animated.div>
      <animated.div
        className={styles.illustration__subcontainer__leaves4}
        style={{ transform: trans4 }}
      >
        <Img fluid={data.leaves4.childImageSharp.fluid} loading="eager" />
      </animated.div>
    </div>
  );

  return (
    <>
      <NavBar />
      <section
        className={styles.section}
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <div className={containerCn}>
          <div className="row">
            <div className="col-12"></div>
            <div className="col-12 col-sm-12 col-lg-7">
              <div>
                <h1>Denk Strom neu - denk Strom digital!</h1>
                <h2>Dein Stromanbieter mit der Energiemonitoring App</h2>
              </div>
              <div
                id="main-illu-tablet"
                className={classnames(
                  "col-12 d-lg-none",
                  styles.illustration__column
                )}
              >
                {illustration}
              </div>
              <div className={styles.downloads__container}>
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between justify-content-lg-start">
                  <ButtonWithDropDown
                    variant="orange"
                    className={styles.downloads__links}
                  >
                    App Download
                  </ButtonWithDropDown>
                  <ButtonLink id="tarif berechnen" to="#calculate">
                    Tarif berechnen
                  </ButtonLink>
                </div>
              </div>
            </div>
            <div id="main-illu" className={illustrationColumnCn}>
              {illustration}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainscreenSection;
