import React, { ReactElement, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import cn from "classnames";
import styles from "./styles.mod.scss";
import { useScroll } from "../../hooks/useScroll";

interface WhyStromeeProps {
  isScroll?: boolean;
}

function WhyStromee({ isScroll }: WhyStromeeProps): ReactElement {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pie-chart.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 637) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const whyStromeeItemHeading = cn(
    "d-flex",
    "flex-row",
    "align-items-center",
    styles.why_stromee__item_heading
  );

  const whyStromeeRowCn = cn("row", styles.why_stromee__row);

  const lightningIcon = (
    <svg width="14px" height="30px" viewBox="0 0 14 30" version="1.1">
      <g
        id="1.1-Main-page-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI_Landing"
          transform="translate(-156.000000, -2125.000000)"
          fill="#526DF2"
        >
          <g id="bullets" transform="translate(150.000000, 2124.000000)">
            <path
              d="M6,2.60865699 L6,17.0865699 C6,17.9713312 6.68045458,18.6952268 7.51212129,18.6952268 L10.5363639,18.6952268 L10.5363639,30.1971243 C10.5363639,31.0175394 11.5494851,31.3070976 11.9426367,30.5992885 L19.7905461,16.2822414 C20.3802734,15.2044412 19.6544552,13.8692559 18.4901218,13.8692559 L15.0727277,13.8692559 L18.8379097,3.17168693 C19.21594,2.12605989 18.4901218,1 17.4316369,1 L7.51212129,1 C6.68045458,1 6,1.72389564 6,2.60865699 Z"
              id="Path-Copy"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
  const homeRef = useRef(null);
  useScroll(isScroll || false, homeRef);
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.heading_container}>
              <h2 ref={homeRef} id="home">
                Was macht stromee so besonders?
              </h2>
              <p>
                Bei stromee bekommst du Ökostrom zum Einkaufspreis. Das macht
                uns zu einem der wenigen Energieversorger, die nichts an deinem
                Verbrauch verdienen!
              </p>
            </div>
          </div>
        </div>
        <div className={whyStromeeRowCn}>
          <div className="col-12 col-lg-5">
            <div className={styles.why_stromee__item}>
              <div className={whyStromeeItemHeading}>
                {lightningIcon}
                <p>Was heißt das?</p>
              </div>
              <p className={styles.why_stromee__item_description}>
                Im Gegensatz zu den klassischen Stromanbietern verdienen wir
                also nicht umso mehr, je mehr Strom du nutzt. Im Durchschnitt
                sparst du damit bis zu 20%!
              </p>
            </div>
            <div className={styles.why_stromee__item}>
              <div className={whyStromeeItemHeading}>
                {lightningIcon}
                <p>Aber wie verdienen wir dann?</p>
              </div>
              <p className={styles.why_stromee__item_description}>
                Unsere Kosten decken wir allein über unsere monatliche
                Grundgebühr. Das finden wir fair. Für dich und für uns.
              </p>
            </div>
            <div className={styles.why_stromee__item}>
              <div className={whyStromeeItemHeading}>
                {lightningIcon}
                <p>Wozu das Ganze?</p>
              </div>
              <p className={styles.why_stromee__item_description}>
                Nur so ist es unserer Meinung nach glaubhaft, dass uns dein
                Energiesparen am Herzen liegt. Gemeinsam zu mehr Effizienz. Das
                ist unser Ziel!
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className={styles.diagram__container}>
              <Img fluid={data.file.childImageSharp.fluid} loading="eager" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyStromee;
