import mention_1 from "../../assets/Mentions/mention_01.png";
import mention_2 from "../../assets/Mentions/mention_02.png";
import mention_3 from "../../assets/Mentions/mention_03.png";
import mention_4 from "../../assets/Mentions/mention_04.png";
import mention_5 from "../../assets/Mentions/mention_05.png";

import { mention } from "./types";

export const mentionsData: mention[] = [
  {
    image: mention_1,
    description: "life PR",
    source: {
      link: true,
      url:
        "https://www.lifepr.de/pressemitteilung/homee-gmbh/Revolution-am-deutschen-Strommarkt-stromee-bietet-Oekostrom-zum-Einkaufspreis/boxid/812171",
    },
  },
  {
    image: mention_2,
    description: "Zeitung für kommunale Wirtschaft",
    source: {
      link: false,
    },
  },
  {
    image: mention_3,
    description: "Energie Informationsdienst",
    source: {
      link: true,
      url:
        "https://www.eid-aktuell.de/nachrichten/nachrichtenarchiv/detail/news/berliner-start-up-bietet-oekostrom-zum-einkaufspreis.html",
    },
  },
  {
    image: mention_4,
    description: "deutsche startups",
    source: {
      link: true,
      url:
        "https://www.deutsche-startups.de/2020/10/01/5-junge-startups-schirm/",
    },
  },
  {
    image: mention_5,
    description: "PowerCloud",
    source: {
      link: true,
      url:
        "https://power.cloud/presse/stromee/?utm_source=Owned%20Social%20Media&utm_medium=LinkedIn%20Banner&utm_campaign=PM%20stromee",
    },
  },
];
