import React, { ReactElement } from "react";

function mapToRange(
  value: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number {
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
}

export const Star: React.FC<{
  value: number;
  id: string;
}> = function Score({ value, id }): ReactElement {
  const width = mapToRange(value, 0, 1, 6.24, 93.77);
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={`mask${id}`}>
          <rect width={width} height="100" fill="white" />
        </mask>
      </defs>

      <use href={`#mask${id}`} fill="black" />

      <svg mask={`url(#mask${id})`} overflow="hidden">
        <path
          d="M62.2433 32.1768L52.8337 5.10644C51.8998 2.41977 48.1002 2.41975 47.1663 5.10644L37.7567 32.1768C37.6193 32.5718 37.2507 32.8397 36.8325 32.8482L8.17935 33.4321C5.33558 33.4901 4.16143 37.1037 6.42803 38.8221L29.2657 56.1364C29.599 56.3891 29.7398 56.8225 29.6187 57.2229L21.3197 84.6541C20.4961 87.3765 23.57 89.6099 25.9047 87.9853L49.4288 71.6157C49.7721 71.3768 50.2279 71.3768 50.5712 71.6157L74.0953 87.9853C76.43 89.6099 79.5039 87.3765 78.6803 84.6541L70.3813 57.2229C70.2602 56.8225 70.401 56.3891 70.7343 56.1364L93.572 38.8221C95.8386 37.1037 94.6644 33.4901 91.8207 33.4321L63.1675 32.8482C62.7493 32.8397 62.3807 32.5718 62.2433 32.1768Z"
          fill="#FCE33F"
        />
        <path
          d="M62.2433 32.1768L52.8337 5.10644C51.8998 2.41977 48.1002 2.41975 47.1663 5.10644L37.7567 32.1768C37.6193 32.5718 37.2507 32.8397 36.8325 32.8482L8.17935 33.4321C5.33558 33.4901 4.16143 37.1037 6.42803 38.8221L29.2657 56.1364C29.599 56.3891 29.7398 56.8225 29.6187 57.2229L21.3197 84.6541C20.4961 87.3765 23.57 89.6099 25.9047 87.9853L49.4288 71.6157C49.7721 71.3768 50.2279 71.3768 50.5712 71.6157L74.0953 87.9853C76.43 89.6099 79.5039 87.3765 78.6803 84.6541L70.3813 57.2229C70.2602 56.8225 70.401 56.3891 70.7343 56.1364L93.572 38.8221C95.8386 37.1037 94.6644 33.4901 91.8207 33.4321L63.1675 32.8482C62.7493 32.8397 62.3807 32.5718 62.2433 32.1768Z"
          fill="url(#paint0_linear)"
          fillOpacity="0.8"
        />
        <path
          d="M62.2433 32.1768L52.8337 5.10644C51.8998 2.41977 48.1002 2.41975 47.1663 5.10644L37.7567 32.1768C37.6193 32.5718 37.2507 32.8397 36.8325 32.8482L8.17935 33.4321C5.33558 33.4901 4.16143 37.1037 6.42803 38.8221L29.2657 56.1364C29.599 56.3891 29.7398 56.8225 29.6187 57.2229L21.3197 84.6541C20.4961 87.3765 23.57 89.6099 25.9047 87.9853L49.4288 71.6157C49.7721 71.3768 50.2279 71.3768 50.5712 71.6157L74.0953 87.9853C76.43 89.6099 79.5039 87.3765 78.6803 84.6541L70.3813 57.2229C70.2602 56.8225 70.401 56.3891 70.7343 56.1364L93.572 38.8221C95.8386 37.1037 94.6644 33.4901 91.8207 33.4321L63.1675 32.8482C62.7493 32.8397 62.3807 32.5718 62.2433 32.1768Z"
          fill="url(#paint1_linear)"
          fillOpacity="0.6"
        />

        <g>
          <path
            d="M51.8205 5.26147C51.0995 3.64565 48.7126 3.70341 48.1108 5.43477L38.7011 32.5051C38.4265 33.2952 37.6891 33.831 36.8528 33.848L8.19962 34.4319C6.30378 34.4706 5.52101 36.8796 7.03208 38.0252L7.45129 38.3431C6.82206 37.1234 7.62874 35.4639 9.19962 35.4319L37.8528 34.848C38.6891 34.831 39.4265 34.2952 39.7011 33.5051L49.1108 6.43475C49.5328 5.22056 50.8328 4.82947 51.8205 5.26147Z"
            fill="#FFF8D8"
          />
          <path
            d="M51.8205 5.26147C51.0995 3.64565 48.7126 3.70341 48.1108 5.43477L38.7011 32.5051C38.4265 33.2952 37.6891 33.831 36.8528 33.848L8.19962 34.4319C6.30378 34.4706 5.52101 36.8796 7.03208 38.0252L7.45129 38.3431C6.82206 37.1234 7.62874 35.4639 9.19962 35.4319L37.8528 34.848C38.6891 34.831 39.4265 34.2952 39.7011 33.5051L49.1108 6.43475C49.5328 5.22056 50.8328 4.82947 51.8205 5.26147Z"
            fill="url(#paint2_linear)"
            fillOpacity="0.8"
          />
          <path
            d="M23.3593 87.3433C23.1731 86.9353 23.1229 86.4523 23.2768 85.9436L31.5758 58.5124C31.818 57.7117 31.5363 56.8449 30.8697 56.3395L30.436 56.0107C30.6716 56.4642 30.7303 57.0018 30.5758 57.5124L22.2768 84.9436C21.9559 86.0043 22.5224 86.9536 23.3593 87.3433Z"
            fill="#FFF8D8"
          />
          <path
            d="M23.3593 87.3433C23.1731 86.9353 23.1229 86.4523 23.2768 85.9436L31.5758 58.5124C31.818 57.7117 31.5363 56.8449 30.8697 56.3395L30.436 56.0107C30.6716 56.4642 30.7303 57.0018 30.5758 57.5124L22.2768 84.9436C21.9559 86.0043 22.5224 86.9536 23.3593 87.3433Z"
            fill="url(#paint4_linear)"
            fillOpacity="0.8"
          />
        </g>

        <g style={{ mixBlendMode: "darken" }}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.6953 87.4649C24.2175 87.6038 24.8045 87.5332 25.3344 87.1644L48.8585 70.7949C49.0573 70.6566 49.275 70.5583 49.5006 70.5001C48.947 70.3571 48.3456 70.4554 47.8578 70.7949L24.3337 87.1644C24.1272 87.3081 23.912 87.4065 23.6953 87.4649ZM75.3056 87.465C76.7218 87.8468 78.2003 86.5179 77.724 84.9436L69.425 57.5124C69.1828 56.7118 69.4644 55.8449 70.131 55.3395L92.9687 38.0253C94.4798 36.8797 93.697 34.4706 91.8012 34.4319L63.148 33.848C62.3117 33.831 61.5743 33.2952 61.2996 32.5051L51.89 5.43479C51.5123 4.34815 50.4314 3.92076 49.5005 4.1526C50.104 4.30293 50.6444 4.73032 50.8892 5.43476L60.2989 32.5051C60.5735 33.2952 61.3109 33.831 62.1473 33.848L90.8004 34.4319C92.6962 34.4705 93.479 36.8796 91.9679 38.0252L69.1303 55.3395C68.4637 55.8449 68.182 56.7117 68.4243 57.5124L76.7232 84.9436C77.0854 86.1407 76.3172 87.196 75.3056 87.465Z"
            fill="#FEB63D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.6953 87.4649C24.2175 87.6038 24.8045 87.5332 25.3344 87.1644L48.8585 70.7949C49.0573 70.6566 49.275 70.5583 49.5006 70.5001C48.947 70.3571 48.3456 70.4554 47.8578 70.7949L24.3337 87.1644C24.1272 87.3081 23.912 87.4065 23.6953 87.4649ZM75.3056 87.465C76.7218 87.8468 78.2003 86.5179 77.724 84.9436L69.425 57.5124C69.1828 56.7118 69.4644 55.8449 70.131 55.3395L92.9687 38.0253C94.4798 36.8797 93.697 34.4706 91.8012 34.4319L63.148 33.848C62.3117 33.831 61.5743 33.2952 61.2996 32.5051L51.89 5.43479C51.5123 4.34815 50.4314 3.92076 49.5005 4.1526C50.104 4.30293 50.6444 4.73032 50.8892 5.43476L60.2989 32.5051C60.5735 33.2952 61.3109 33.831 62.1473 33.848L90.8004 34.4319C92.6962 34.4705 93.479 36.8796 91.9679 38.0252L69.1303 55.3395C68.4637 55.8449 68.182 56.7117 68.4243 57.5124L76.7232 84.9436C77.0854 86.1407 76.3172 87.196 75.3056 87.465Z"
            fill="url(#paint3_linear)"
            fillOpacity="0.8"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="96.8433"
            y1="113.146"
            x2="0.878821"
            y2="17.6597"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEB63D" />
            <stop offset="1" stopColor="#FCE33F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="10.5833"
            y1="-0.708811"
            x2="52.247"
            y2="47.0684"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF8D8" />
            <stop offset="1" stopColor="#FFF8D8" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="-6"
            y1="-2"
            x2="74.5"
            y2="87"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF8D8" stopOpacity="0.2" />
            <stop offset="1" stopColor="#FCE33F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="15"
            y1="-0.5"
            x2="87"
            y2="100"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF8D8" stopOpacity="0.2" />
            <stop offset="1" stopColor="#FEB63D" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="-6"
            y1="-2"
            x2="74.5"
            y2="87"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF8D8" stopOpacity="0.2" />
            <stop offset="1" stopColor="#FCE33F" />
          </linearGradient>
        </defs>
      </svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.7783 4.77811L63.1879 31.8484L91.8411 32.4323C95.6328 32.5096 97.1983 37.3278 94.1762 39.619L71.3385 56.9333L79.6375 84.3645C80.7357 87.9945 76.6371 90.9723 73.5241 88.8061L50 72.4366L26.4759 88.8061C23.363 90.9723 19.2644 87.9945 20.3626 84.3645L28.6616 56.9333L5.82392 39.619C2.80179 37.3278 4.36732 32.5096 8.15901 32.4323L36.8121 31.8484L46.2218 4.77811C47.467 1.19586 52.5331 1.19588 53.7783 4.77811ZM51.8892 5.43477C51.2666 3.64365 48.7335 3.64365 48.1109 5.43477L38.7013 32.5051C38.4266 33.2952 37.6892 33.831 36.8529 33.848L8.19976 34.4319C6.30391 34.4706 5.52115 36.8796 7.03222 38.0252L29.8699 55.3395C30.5365 55.8449 30.8181 56.7118 30.5759 57.5124L22.2769 84.9436C21.7278 86.7586 23.7771 88.2475 25.3336 87.1644L48.8577 70.7949C49.5443 70.3171 50.4558 70.3171 51.1424 70.7949L74.6665 87.1644C76.223 88.2475 78.2723 86.7586 77.7232 84.9436L69.4242 57.5124C69.1819 56.7118 69.4636 55.8449 70.1302 55.3395L92.9679 38.0253C94.4789 36.8796 93.6962 34.4706 91.8003 34.4319L63.1472 33.848C62.3108 33.831 61.5735 33.2952 61.2988 32.5051L51.8892 5.43477Z"
        fill="#FF9B3D"
      />
    </svg>
  );
};
