import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import cn from "classnames";
import Img from "gatsby-image";
import Image1 from "../../assets/stromee-app-1.png";
import Image2 from "../../assets/stromee-app-2.png";

import styles from "./styles.mod.scss";

function MobileAppSection(): ReactElement {
  const [isInViewState, setIsInViewState] = useState(false);

  const imageRef = useRef(null);

  function isInViewport(ref: React.RefObject<HTMLDivElement>) {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      return (
        rect.top <= window.innerHeight - rect.height + 100 &&
        rect.bottom >= rect.height - 100
      );
    }
    return false;
  }
  function onScroll() {
    setIsInViewState(isInViewport(imageRef));
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mobile-app-bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2101) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageContainerCn = cn(styles.image__container, {
    [styles.rotated__image]: isInViewState,
  });

  return (
    <section className={styles.section}>
      <div className={styles.section__background}>
        <div className={styles.background__subcontainer}>
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className={styles.section__heading}>stromee App</h2>
          </div>
        </div>
        <div className={cn("row", styles.image__row)} ref={imageRef}>
          <div className="col-6">
            <div className={imageContainerCn}>
              <img alt="image-1" src={Image1} />
            </div>
          </div>
          <div className="col-6">
            <div className={imageContainerCn}>
              <img alt="image-2" src={Image2} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobileAppSection;
