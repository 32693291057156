import React, { ReactElement } from "react";
import cn from "classnames";

import styles from "./styles.mod.scss";

import { rating } from "./types";
import { ratingsData } from "./RatingsData";
import { Star } from "./star";

const Score: React.FC<{
  score: number;
  outerIndex: number;
}> = function Score({ score, outerIndex }): ReactElement {
  const stars = [];

  let index = 0;
  while (index < score) {
    stars.push(
      <Star
        value={1}
        key={index}
        id={`${outerIndex.toString()}${index.toString()}`}
      />
    );
    index++;
  }
  if (score % 1 != 0) {
    const value: number = Math.round((score % 1) * 10) / 10;
    stars[stars.length - 1] = (
      <Star
        value={value}
        key={index - 1}
        id={`${outerIndex.toString()}${(index - 1).toString()}`}
      />
    );
  }

  while (index < 5) {
    stars.push(
      <Star
        value={0}
        key={index}
        id={`${outerIndex.toString()}${index.toString()}`}
      />
    );
    index++;
  }
  return <div className={styles.star_container}>{stars}</div>;
};

export default function Ratings(): ReactElement {
  const containerCn = cn("container", styles.container);
  return (
    <section>
      <div className={styles.section_container}>
        <div className={containerCn}>
          <div className={styles.outer_container}>
            {ratingsData.map((data: rating, index: number) => (
              <div className={styles.inner_container} key={index}>
                <Score score={data.score} outerIndex={index} />
                <p className={styles.description}>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
