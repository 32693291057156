import { rating } from "./types";

export const ratingsData: rating[] = [
  {
    score: 4.6,
    description: "Google Rating",
  },
  {
    score: 4.5,
    description: "AppStore",
  },
  {
    score: 4.4,
    description: "Google Play",
  },
];
