import React, { ReactElement, useEffect } from "react";
import cn from "classnames";

import styles from "./styles.mod.scss";

import {
  TestimonialItem as TestimonialItemType,
  TestimonialData,
  SVGReactComponent,
} from "./types";
import { testimonialData } from "./TestimonialData";

const ProfileIcon: SVGReactComponent = function ProfileIcon() {
  return (
    <svg
      width="36"
      height="96"
      viewBox="0 0 36 96"
      preserveAspectRatio="xMinYMin meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 56C0 47.1634 7.16344 40 16 40H20C28.8366 40 36 47.1634 36 56V92C36 94.2091 34.2091 96 32 96H4C1.79086 96 0 94.2091 0 92V56Z"
        fill="currentColor"
      />
      <circle cx="18" cy="18" r="18" fill="currentColor" />
      <path
        pathLength="2"
        className={styles.testimonial_item_icon_smile}
        d="M28.2115 22.9983C21.3235 28.6125 13.8879 28.6125 7 23"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

const IconComponent: React.FC<{
  fill: string;
  Icon: SVGReactComponent;
}> = function IconComponent({ fill, Icon }): ReactElement {
  return (
    <div
      style={{ color: fill }}
      className={`${styles.testimonial_item_icon} flex-grow-0 flex-shrink-0`}
    >
      <Icon />
    </div>
  );
};

const TestimonialItem: React.FC<{
  data: TestimonialItemType;
}> = function Testimonial({ data }): ReactElement {
  return (
    <div className={`row align-items-md-center`}>
      <div
        className={`${styles.testimonial_item_inner_container} col-12 d-flex align-items-end`}
      >
        <p className={`${styles.description}`}>
          {data.description}

          <span className={`${styles.source}`}>
            {data.source.link && (
              <a href={data.source.url} target="_blank" rel="noreferrer">
                {data.source.source}
              </a>
            )}
            {!data.source.link && data.source.source}
          </span>
        </p>
        {
          <IconComponent
            fill={data.fill || "#8BEEBC"}
            Icon={data.icon || ProfileIcon}
          />
        }
      </div>
    </div>
  );
};

export default function Testimonial(): ReactElement {
  const containerCn = cn("container", styles.container);

  const [testimonalNumber, setTestimonialNumber] = React.useState<number>(0);

  const changeTestimonialParams = () => {
    if (window.innerWidth > 992) {
      setTestimonialNumber(2);
    } else if (window.innerWidth > 767) {
      setTestimonialNumber(1);
    } else {
      setTestimonialNumber(0);
    }
  };

  useEffect(() => {
    changeTestimonialParams();
    window.addEventListener("resize", changeTestimonialParams);

    return () => {
      window.removeEventListener("resize", changeTestimonialParams);
    };
  }, []);

  function renderTestimonials(td: TestimonialData) {
    switch (testimonalNumber) {
      case 2:
      case 1:
        return (
          <div className={`${styles.testimonial_item_outer_container} d-flex`}>
            <div>
              {td.data.map(
                (testimonialItem: TestimonialItemType, index: number) => {
                  if (index % 2 == 0) {
                    return (
                      <TestimonialItem data={testimonialItem} key={index} />
                    );
                  }
                }
              )}
            </div>
            <div>
              {td.data.map(
                (testimonialItem: TestimonialItemType, index: number) => {
                  if (index % 2 == 1) {
                    return (
                      <TestimonialItem data={testimonialItem} key={index} />
                    );
                  }
                }
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className={`${styles.testimonial_item_outer_container} `}>
            {td.data.map(
              (testimonialItem: TestimonialItemType, index: number) => (
                <TestimonialItem data={testimonialItem} key={index} />
              )
            )}
          </div>
        );
    }
  }

  return (
    <section>
      <div className={styles.section_container}>
        <div className={containerCn}>
          <h2>{testimonialData.title}</h2>
          {renderTestimonials(testimonialData)}
        </div>
      </div>
    </section>
  );
}
