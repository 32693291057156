import React, { ReactElement } from "react";
import cn from "classnames";

import styles from "./styles.mod.scss";

import { mention } from "./types";
import { mentionsData } from "./MentionsData";

export default function Mentions(): ReactElement {
  const containerCn = cn("container", styles.container);

  return (
    <section>
      <div className={styles.section_container}>
        <div className={containerCn}>
          <h2>Bekannt aus</h2>
          <div className={styles.outer_container}>
            {mentionsData.map(
              (data: mention, index: number) =>
                data.source.link && (
                  <a
                    href={data.source.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <img src={data.image} alt={data.description} />
                  </a>
                )
            )}
            {mentionsData.map(
              (data: mention, index: number) =>
                !data.source.link && (
                  <img src={data.image} alt={data.description} key={index} />
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
