import React, { ReactElement } from "react";
import cn from "classnames";

import styles from "./styles.mod.scss";

const lightningIcon = (
  <svg width="14px" height="30px" viewBox="0 0 14 30" version="1.1">
    <g
      id="1.1-Main-page-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="UI_Landing"
        transform="translate(-156.000000, -2125.000000)"
        fill="#526DF2"
      >
        <g id="bullets" transform="translate(150.000000, 2124.000000)">
          <path
            d="M6,2.60865699 L6,17.0865699 C6,17.9713312 6.68045458,18.6952268 7.51212129,18.6952268 L10.5363639,18.6952268 L10.5363639,30.1971243 C10.5363639,31.0175394 11.5494851,31.3070976 11.9426367,30.5992885 L19.7905461,16.2822414 C20.3802734,15.2044412 19.6544552,13.8692559 18.4901218,13.8692559 L15.0727277,13.8692559 L18.8379097,3.17168693 C19.21594,2.12605989 18.4901218,1 17.4316369,1 L7.51212129,1 C6.68045458,1 6,1.72389564 6,2.60865699 Z"
            id="Path-Copy"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

const whyStromeeItemHeading = cn(
  "d-flex",
  "flex-row",
  "align-items-center",
  styles.why_stromee__item_heading
);

function SeoText(): ReactElement {
  const containerCn = cn("container", styles.container);

  return (
    <section>
      <div className={styles.section_container}>
        <div className={containerCn}>
          <h2>stromee - mehr als einfach Strom</h2>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>Dein digitaler Ökostromanbieter</h3>
              </div>
              <p className={styles.description}>
                Bei stromee bieten wir dir Ökostrom. Aber nicht nur irgendeinen
                Ökostrom: wir sind dein digitaler Ökostromanbieter. Bedeutet für
                dich: du hast deinen Stromvertrag und die Tarifdetails digital
                auf deinem Handy immer zur Hand. Digitaler Ökostrom heißt für
                uns doppelte Nachhaltigkeit. Alle unsere Vorgänge sind digital,
                das ist nachhaltiger als der ständige Papierverbrauch. Und beim
                Strom bieten wir dir Ökostrom aus reiner Wasserkraft.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>Mit der stromee App zum digitalen Energiemonitoring</h3>
              </div>
              <p className={styles.description}>
                Du willst die Kontrolle über deinen Stromverbrauch?
                Energiemonitoring per Handy? Wir haben da eine App für dich. Mit
                der stromee App trackst du nicht nur deinen Stromverbrauch,
                sondern hast auch alle Details zu deinem Stromtarif und deinem
                Stromvertrag immer parat. Unsere App gibt’s natürlich kostenlos
                bei Google Play und im App Store zum Download. Starte noch heute
                mit deinem digitalen Energiemonitoring per App.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>100 % nachhaltiger Ökostrom aus reiner Wasserkraft</h3>
              </div>
              <p className={styles.description}>
                stromee Strom ist Ökostrom, klar. Und falls du dich schon immer
                gefragt hast, warum unsere stromee Webseite und unsere App so
                blau sind: weil wir “blauen” Ökostrom bieten - aus 100 % reiner
                Wasserkraft. Strom aus erneuerbaren Energien - also erneuerbaren
                Quellen - hilft dem Klima und hilft dir. Als nachhaltiger
                Energieversorger möchten wir dazu beitragen, dass mehr Ökostrom
                ins deutsche Stromnetz fließt.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>Transparente Strompreise für mehr Durchblick</h3>
              </div>
              <p className={styles.description}>
                Wir möchten dir zeigen, wie sich dein Strompreis zusammensetzt
                und wie dein Strompreis zustande kommt. So kannst du
                nachvollziehen, was du an Cents pro Kilowattstunde zahlst, an
                wen und warum.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>
                  Geld sparen mit dem stromee Tarif: Ökostrom zum Einkaufspreis
                  und langfristige Preisgarantie
                </h3>
              </div>
              <p className={styles.description}>
                Bei uns gibt es keine bösen Preisüberraschungen. Wir möchten dir
                mit unseren Ökostrom Tarifen langfristig günstige Preise bieten
                können, mit denen du Geld sparst. Deshalb bieten wir dir auch
                bei Vertragsabschluss eine langfristige Preisgarantie. Denn ein
                günstiger Strompreis ist immer auch ein langfristig günstiger
                Strompreis.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>
                  Stromvertrag ohne Mindestlaufzeit: dein stromee Vertrag ist
                  monatlich kündbar
                </h3>
              </div>
              <p className={styles.description}>
                Bei uns gibt es kurze Vertragslaufzeiten - so kurz, dass du
                monatlich deinen Stromvertrag kündigen könntest. Aber das
                möchtest du natürlich nicht. Mit unserer Devise keine
                Mindestvertragslaufzeit möchten wir dir einen Stromvertrag
                bieten, der flexibel ist. Denn in einer guten Partnerschaft
                lässt man sich gegenseitig genügend Freiraum.
              </p>
            </div>
          </div>
          <div className="row align-items-md-center mb-5">
            <div className="col-12 col-lg-8">
              <div className={whyStromeeItemHeading}>
                <div>{lightningIcon}</div>
                <h3>
                  Strom sparen mit stromee - mehr Nachhaltigkeit im Alltag
                </h3>
              </div>
              <p className={styles.description}>
                Der nachhaltigste Strom ist für uns der, den du gar nicht erst
                verbrauchst. Bei stromee helfen wir dir deshalb beim Strom
                sparen. So kannst ganz nebenbei weniger Strom verbrauchen und so
                noch mehr Nachhaltigkeit in deinen Alltag bringen. Und Strom und
                Energie sparen geht wirklich einfach! Schau dir dazu doch auch
                mal unsere Energiespartipps an.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SeoText;
