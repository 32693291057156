import { TestimonialData } from "./types";

export const testimonialData: TestimonialData = {
  title: "Was sagen andere?",
  data: [
    {
      source: {
        link: true,
        url: "https://apps.apple.com/de/app/id1521872083#see-all/reviews",
        source: "App Store",
      },
      description:
        '"Toller Stromanbieter. Super Service. Top Preis. Moderner Auftritt. Und dazu noch eine übersichtliche App für Energiekostenkontrolle ✨👌🏻"',
    },
    {
      source: {
        link: true,
        source: "Google",
        url: "https://goo.gl/maps/ngsSSMABsBevstK9A",
      },
      description:
        '"Wechsel war problemlos unkompliziert. Der Kontakt zu den Mitarbeitern per E-Mail durchgängig nett und die App ist leicht verständlich und selbsterklärend. Empfehlung!"',
    },
    {
      source: {
        link: true,
        source: "Google",
        url: "https://goo.gl/maps/MHbtGjSqfgBAAzx89",
      },
      description:
        '"Top Kommunikation, einfache Handhabung der App, super Kundenservice. Alle Fragen wurden stets vernünftig beantwortet. Nur zu empfehlen 👍🏻"',
    },
    {
      source: {
        link: true,
        url: "https://apps.apple.com/de/app/id1521872083#see-all/reviews",
        source: "App Store",
      },
      description:
        '"Super Verbrauchskontrolle Funktion. Mir gefällt, dass ich alles über die App machen kann. Das beste ist, dass ich kontrollieren kann, wie viel Energie ich verbrauche - z. B. wie viel extra Energie ich in den Home Office Monaten verbraucht habe."',
    },
  ],
};
