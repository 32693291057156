import React, { ReactElement } from "react";
import cn from "classnames";

import styles from "./styles.mod.scss";

function KeyMessages(): ReactElement {
  const containerCn = cn("container", styles.container);

  const desktopHeadingCn = cn("d-none", "d-lg-block", styles.heading__desktop);

  return (
    <section>
      <div className={styles.section_container}>
        <div className={styles.background}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 2000 549"
          >
            <g fill="none" fillRule="evenodd">
              <path
                fill="#FFF"
                d="M963.235 120.93l-32.851 19.752c-3.112 1.871-7.152.886-9.023-2.2-.638-1.051-.963-2.261-.94-3.49l.784-40.517c.07-3.608 3.07-6.488 6.7-6.431 1.237.019 2.442.385 3.476 1.055l32.068 20.764c3.037 1.967 3.887 6.014 1.897 9.04-.542.825-1.264 1.517-2.111 2.027z"
                transform="translate(-78.751 .062)"
              />
              <path
                fill="#F6F9FA"
                fillRule="nonzero"
                d="M927.751 80.355c-240.343 0-459 194-569 194-74 1.666-224.74-131.247-323.914-39.917-99.174 91.331 44.915 299.666 44.914 313.917h1999c18-279.035 27-449.228 27-510.578 0-92.025-240.11 8.769-307 42.578-66.89 33.81-127.265 83.585-320.274 83.585-193.01 0-247.753-83.585-550.726-83.585z"
                transform="translate(-78.751 .062)"
              />
              <path
                fill="#FFF"
                d="M1314.979 159.434l82.376 79.22c7.804 7.504 8.05 19.912.55 27.715-2.556 2.657-5.808 4.54-9.386 5.431l-117.903 29.384c-10.501 2.618-21.138-3.776-23.758-14.28-.893-3.578-.758-7.335.388-10.84l35.527-108.603c3.365-10.288 14.433-15.896 24.72-12.527 2.801.918 5.36 2.456 7.486 4.5zM727.157 178.79l53.29 184.955c5.049 17.52-5.014 35.83-22.474 40.894-5.948 1.726-12.26 1.733-18.212.02l-196.163-56.44c-17.471-5.027-27.573-23.314-22.564-40.845 1.707-5.972 5.058-11.34 9.668-15.486l142.872-128.516c13.534-12.173 34.34-11.033 46.473 2.546 3.305 3.7 5.735 8.099 7.11 12.871zM964.235 141.347l-32.851 19.752c-3.112 1.871-7.152.886-9.023-2.2-.638-1.051-.963-2.261-.94-3.49l.784-40.517c.07-3.608 3.07-6.488 6.7-6.431 1.237.019 2.442.385 3.476 1.055l32.068 20.764c3.037 1.967 3.887 6.014 1.897 9.04-.542.825-1.264 1.517-2.111 2.027zM1689.985 349.811l-15.448 62.554c-1.463 5.926-7.454 9.543-13.38 8.078-2.019-.499-3.856-1.557-5.3-3.052l-47.616-49.302c-4.241-4.39-4.12-11.387.271-15.627 1.496-1.444 3.369-2.437 5.403-2.865l63.064-13.253c5.973-1.255 11.835 2.57 13.091 8.543.343 1.627.314 3.31-.085 4.924z"
                transform="translate(-78.751 .062)"
              />
            </g>
          </svg>
        </div>
        <div className={containerCn}>
          <div className="row align-items-md-center">
            <div className="col-12 col-lg-8">
              <div className="d-flex flex-row align-items-center d-lg-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20%"
                  height="100%"
                  viewBox="0 0 262 262"
                  className={styles.icon__mobile}
                >
                  <path
                    fill="#FCE33F"
                    fillRule="evenodd"
                    d="M77.425 66.986l-3.434-3.44c-3.434-3.439-8.982-3.35-12.327 0l-.089.089c-3.433 3.44-3.433 8.995 0 12.346l3.434 3.44c3.434 3.439 8.894 3.439 12.327 0l.089-.089c3.433-3.35 3.433-8.995 0-12.346zM50.75 121h-8.5c-4.583 0-8.25 3.98-8.25 8.954v.09c0 4.975 3.667 8.954 8.25 8.954h8.417c4.666.09 8.333-3.889 8.333-8.863v-.09c0-5.065-3.667-9.045-8.25-9.045zm82.34-85H133c-5.04 0-9 3.741-9 8.418v8.163c0 4.676 3.96 8.418 8.91 8.418h.09c5.04.085 9-3.657 9-8.333v-8.248c0-4.677-3.96-8.418-8.91-8.418zm68.335 27.596c-3.434-3.446-8.982-3.446-12.416-.088l-3.434 3.446c-3.433 3.447-3.433 9.014 0 12.373l.089.088c3.433 3.447 8.98 3.447 12.327 0l3.434-3.447c3.433-3.446 3.433-8.925 0-12.372zm-15.86 132.385l3.437 3.44c3.435 3.439 8.985 3.439 12.421 0 3.436-3.44 3.436-8.996 0-12.435l-3.436-3.44c-3.435-3.439-8.985-3.35-12.333 0-3.524 3.528-3.524 8.996-.088 12.435zM204 129.955v.09c0 4.975 3.679 8.955 8.278 8.955h8.444c4.6 0 8.278-3.98 8.278-8.955v-.09c0-4.975-3.679-8.955-8.278-8.955h-8.444c-4.6 0-8.278 3.98-8.278 8.955zM132 79c-29.79 0-54 23.762-54 53s24.21 53 54 53 54-23.762 54-53-24.21-53-54-53zm.955 148h.09c4.975 0 8.955-3.741 8.955-8.418v-8.164c0-4.677-3.98-8.418-8.955-8.418h-.09c-4.975 0-8.955 3.741-8.955 8.418v8.164c0 4.677 3.98 8.418 8.955 8.418zm-71.366-27.575c3.438 3.433 8.99 3.433 12.428 0l3.438-3.434c3.437-3.434 3.35-8.982 0-12.327l-.088-.089c-3.438-3.433-8.99-3.433-12.428 0L61.5 187.01c-3.35 3.522-3.35 8.982.088 12.416z"
                  />
                </svg>
                <h3 className={styles.heading}>
                  transparent & <br /> einfach
                </h3>
              </div>
              <h3 className={desktopHeadingCn}>transparent & einfach</h3>
              <p className={styles.description}>
                Bei uns sind die Preise transparent, die Rechnungen einfach und
                das Wechseln super easy. Du kannst uns auch gern deine Rechnung
                an <a href="mailto:hello@stromee.de">hello@stromee.de</a> senden
                und wir übernehmen den Wechsel für dich.
              </p>
            </div>
            <div className="d-none d-lg-block col-lg-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="262"
                height="262"
                viewBox="0 0 262 262"
              >
                <path
                  fill="#FCE33F"
                  fillRule="evenodd"
                  d="M77.425 66.986l-3.434-3.44c-3.434-3.439-8.982-3.35-12.327 0l-.089.089c-3.433 3.44-3.433 8.995 0 12.346l3.434 3.44c3.434 3.439 8.894 3.439 12.327 0l.089-.089c3.433-3.35 3.433-8.995 0-12.346zM50.75 121h-8.5c-4.583 0-8.25 3.98-8.25 8.954v.09c0 4.975 3.667 8.954 8.25 8.954h8.417c4.666.09 8.333-3.889 8.333-8.863v-.09c0-5.065-3.667-9.045-8.25-9.045zm82.34-85H133c-5.04 0-9 3.741-9 8.418v8.163c0 4.676 3.96 8.418 8.91 8.418h.09c5.04.085 9-3.657 9-8.333v-8.248c0-4.677-3.96-8.418-8.91-8.418zm68.335 27.596c-3.434-3.446-8.982-3.446-12.416-.088l-3.434 3.446c-3.433 3.447-3.433 9.014 0 12.373l.089.088c3.433 3.447 8.98 3.447 12.327 0l3.434-3.447c3.433-3.446 3.433-8.925 0-12.372zm-15.86 132.385l3.437 3.44c3.435 3.439 8.985 3.439 12.421 0 3.436-3.44 3.436-8.996 0-12.435l-3.436-3.44c-3.435-3.439-8.985-3.35-12.333 0-3.524 3.528-3.524 8.996-.088 12.435zM204 129.955v.09c0 4.975 3.679 8.955 8.278 8.955h8.444c4.6 0 8.278-3.98 8.278-8.955v-.09c0-4.975-3.679-8.955-8.278-8.955h-8.444c-4.6 0-8.278 3.98-8.278 8.955zM132 79c-29.79 0-54 23.762-54 53s24.21 53 54 53 54-23.762 54-53-24.21-53-54-53zm.955 148h.09c4.975 0 8.955-3.741 8.955-8.418v-8.164c0-4.677-3.98-8.418-8.955-8.418h-.09c-4.975 0-8.955 3.741-8.955 8.418v8.164c0 4.677 3.98 8.418 8.955 8.418zm-71.366-27.575c3.438 3.433 8.99 3.433 12.428 0l3.438-3.434c3.437-3.434 3.35-8.982 0-12.327l-.088-.089c-3.438-3.433-8.99-3.433-12.428 0L61.5 187.01c-3.35 3.522-3.35 8.982.088 12.416z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section_container}>
        <div className={styles.background}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 1130 252"
          >
            <g fill="none" fillRule="evenodd">
              <path
                fill="#ECF0F1"
                fillRule="nonzero"
                d="M1084.01 23.946l53.698 185.692c5.087 17.59-5.052 35.972-22.646 41.058-5.993 1.732-12.354 1.739-18.35.02L899.05 194.05c-17.605-5.047-27.784-23.407-22.736-41.008 1.72-5.996 5.096-11.385 9.741-15.548L1030.019 8.468c13.637-12.223 34.602-11.078 46.827 2.556 3.33 3.713 5.78 8.13 7.165 12.922zM451.517 82.882l21.967 76.635c2.081 7.26-2.067 14.846-9.264 16.945-2.452.715-5.054.717-7.507.008l-80.861-23.386c-7.202-2.083-11.367-9.66-9.301-16.923.703-2.475 2.084-4.699 3.985-6.417l58.893-53.25c5.58-5.044 14.156-4.571 19.157 1.055 1.362 1.533 2.364 3.356 2.931 5.333zM174.76 119.362l-8.27 31.138c-.783 2.95-3.81 4.705-6.76 3.921-1.004-.267-1.913-.812-2.622-1.572l-23.374-25.062c-2.082-2.233-1.96-5.73.272-7.81.76-.71 1.705-1.19 2.726-1.386l31.642-6.075c2.998-.575 5.895 1.388 6.47 4.385.158.816.128 1.658-.085 2.461zM45.75 227.994l-27.577 16.654c-2.613 1.578-6.01.739-7.587-1.874-.537-.89-.813-1.914-.795-2.954l.596-34.265c.053-3.052 2.57-5.483 5.621-5.43 1.04.02 2.053.33 2.923.898l26.982 17.61c2.556 1.669 3.276 5.094 1.609 7.65-.454.696-1.06 1.28-1.772 1.71z"
                transform="translate(-9.022)"
              />
              <path
                d="M682.022 73L802.022 73 802.022 193 682.022 193z"
                transform="translate(-9.022)"
              />
            </g>
          </svg>
        </div>
        <div className={containerCn}>
          <div className="row align-items-md-center">
            <div className="d-none d-lg-block col-lg-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="262"
                height="262"
                viewBox="0 0 262 262"
              >
                <path
                  fill="#4646EB"
                  d="M87 52.491v85.42c0 5.22 4.277 9.49 9.505 9.49h19.01v67.862c0 4.84 6.367 6.549 8.839 2.373l49.33-84.47c3.706-6.36-.856-14.237-8.175-14.237h-21.48l23.666-63.116C170.072 49.643 165.51 43 158.856 43H96.505C91.277 43 87 47.271 87 52.491z"
                />
              </svg>
            </div>
            <div className="col-12 col-lg-8">
              <div className="d-flex flex-row align-items-center d-lg-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20%"
                  height="100%"
                  viewBox="0 0 262 262"
                  className={styles.icon__mobile}
                >
                  <path
                    fill="#4646EB"
                    d="M87 52.491v85.42c0 5.22 4.277 9.49 9.505 9.49h19.01v67.862c0 4.84 6.367 6.549 8.839 2.373l49.33-84.47c3.706-6.36-.856-14.237-8.175-14.237h-21.48l23.666-63.116C170.072 49.643 165.51 43 158.856 43H96.505C91.277 43 87 47.271 87 52.491z"
                  />
                </svg>
                <h3 className={styles.heading}>
                  digital <br /> & innovativ
                </h3>
              </div>
              <h3 className={desktopHeadingCn}>digital & innovativ</h3>
              <p className={styles.description}>
                Endlich Schluss mit dem Papierkram! Mit unserer App war es noch
                nie einfacher, Stromkunde zu werden. So wird Energie zum
                Erlebnis und noch dazu kannst du ganz einfach Stromkosten
                sparen!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section_container}>
        <div className={styles.background}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 2000 546"
          >
            <g fill="none" fillRule="evenodd">
              <path
                fill="#F6F9FA"
                fillRule="nonzero"
                d="M963.37 77.594c-240.343 0-459 194-569 194-114.218 0-107.7-62.5-280-62.5-257.332 0 0 314.744 0 337.5h2000c58.553-277.26 58.553-446.743 0-508.446-58.553-61.703-151.886-48.554-280 39.446-84.844 55.723-191.602 83.584-320.274 83.584-193.01 0-247.753-83.584-550.726-83.584z"
                transform="matrix(-1 0 0 1 2114.37 .406)"
              />
              <path
                fill="#FFF"
                d="M878.142 116.673l-82.376 79.22c-7.804 7.503-8.05 19.911-.55 27.714 2.556 2.657 5.809 4.54 9.386 5.432l117.903 29.384c10.501 2.617 21.138-3.777 23.758-14.281.893-3.578.758-7.335-.388-10.84L910.348 124.7c-3.365-10.288-14.432-15.896-24.72-12.527-2.801.918-5.36 2.456-7.486 4.5zM1583.964 177.445l-53.29 184.955c-5.049 17.52 5.014 35.83 22.474 40.894 5.948 1.726 12.26 1.733 18.212.02l196.163-56.44c17.471-5.027 27.573-23.314 22.564-40.845-1.707-5.972-5.058-11.34-9.668-15.486l-142.872-128.515c-13.534-12.174-34.34-11.034-46.473 2.545-3.304 3.7-5.735 8.1-7.11 12.872zM1228.886 139.585l32.851 19.752c3.112 1.872 7.152.886 9.023-2.2.638-1.051.963-2.26.94-3.49l-.784-40.517c-.07-3.608-3.07-6.488-6.7-6.431-1.237.02-2.442.385-3.476 1.055l-32.068 20.764c-3.037 1.967-3.887 6.014-1.897 9.04.542.825 1.264 1.518 2.111 2.027zM503.136 348.05l15.448 62.554c1.463 5.925 7.454 9.542 13.38 8.077 2.019-.498 3.856-1.556 5.3-3.052l47.616-49.301c4.241-4.392 4.12-11.388-.271-15.628-1.496-1.444-3.369-2.437-5.403-2.864l-63.063-13.254c-5.974-1.255-11.836 2.57-13.092 8.543-.343 1.627-.314 3.31.085 4.924z"
                transform="matrix(-1 0 0 1 2114.37 .406)"
              />
            </g>
          </svg>
        </div>
        <div className={containerCn}>
          <div className="row align-items-md-center">
            <div className="col-12 col-lg-8">
              <div className="d-flex flex-row align-items-center d-lg-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20%"
                  height="100%"
                  viewBox="0 0 262 262"
                  className={styles.icon__mobile}
                >
                  <path
                    fill="#8BEEBC"
                    d="M139.413 166.517c30.925-3.57 55.15-28.472 56.527-59.266 1.56-34.542-27.713-64.71-63.226-65.246-35.972-.446-65.154 27.67-65.154 62.48 0 30.971 23.125 56.588 53.5 61.497v35.167H75.176c-5.048 0-9.177 4.016-9.177 8.925 0 4.91 4.13 8.926 9.177 8.926h110.119c5.047 0 9.176-4.017 9.176-8.926s-4.13-8.925-9.176-8.925h-45.883v-34.632z"
                  />
                </svg>
                <h3 className={styles.heading}>
                  grün & <br /> nachhaltig
                </h3>
              </div>
              <h3 className={desktopHeadingCn}>grün & nachhaltig</h3>
              <p className={styles.description}>
                stromee ist zu 100% aus Wasserkraft und somit umweltfreundlich.
                Aber für uns ist der nachhaltigste Strom der, den du gar nicht
                erst verbrauchst. Wir zeigen dir wie das geht!
              </p>
            </div>
            <div className="d-none d-lg-block col-lg-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="262"
                height="262"
                viewBox="0 0 262 262"
              >
                <path
                  fill="#8BEEBC"
                  d="M139.413 166.517c30.925-3.57 55.15-28.472 56.527-59.266 1.56-34.542-27.713-64.71-63.226-65.246-35.972-.446-65.154 27.67-65.154 62.48 0 30.971 23.125 56.588 53.5 61.497v35.167H75.176c-5.048 0-9.177 4.016-9.177 8.925 0 4.91 4.13 8.926 9.177 8.926h110.119c5.047 0 9.176-4.017 9.176-8.926s-4.13-8.925-9.176-8.925h-45.883v-34.632z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyMessages;
