import React, { ReactElement, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";

import SEO from "../components/Seo";
import MainscreenSection from "../components/MainscreenSection";
import EnergyCalculatorSection from "../components/EnergyCalculatorSection";
import KeyMessages from "../components/KeyMessages";
import Testimonial from "../components/Testimonial";
import Ratings from "../components/Ratings";
import Mentions from "../components/Mentions";
import WhyStromee from "../components/WhyStromee";
import MobileAppSection from "../components/MobileAppSection";
import Carousel from "../components/Carousel";
import SeoText from "../components/SeoText";
import Footer from "../components/Footer";
import Preloader from "../components/SVG/Preloader";
import { PreloaderContainer } from "../components/Contract/Layout/styled";

const Index = ({ location }: RouteComponentProps): ReactElement | null => {
  const [state, setState] = useState("pending");
  const [isCalculate, setIsCalculate] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isHints, setIsHints] = useState(false);
  const [isContact, setIsContact] = useState(false);
  useEffect(() => {
    switch (location?.hash) {
      case "#contact":
        setIsContact(true);
        break;
      case "#calculate":
        setIsCalculate(true);
        break;
      case "#home":
        setIsHome(true);
        break;
      case "#hints":
        setIsHints(true);
        break;
      default:
        break;
    }

    const redirectToEmailVerification = () => {
      const rx = new RegExp("[?&]token=([^&]+).*$");
      const token = location?.search ? location.search.match(rx) : "";

      if (token?.length) {
        navigate(`/email-verification/${token[1]}`);
        setState("token");
      } else {
        setState("default");
      }
    };

    const redirectToChangeEmail = () => {
      const rx = new RegExp("[?&]token=([^&]+).*$");
      const token = location?.search ? location.search.match(rx) : "";

      if (token?.length) {
        navigate(`/change-email/${token[1]}`);
        setState("token");
      } else {
        setState("default");
      }
    };

    if (URL && location) {
      const params = new URL(location.href).searchParams;
      const action = params.get("action");
      const token = params.get("token");
      const email = params.get("email");

      const passwordRecoveryActions = ["setPassword", "confirmPassword"];

      if (action === "changeEmail") {
        redirectToChangeEmail();
      } else if (
        action &&
        passwordRecoveryActions.includes(action) &&
        token &&
        email
      ) {
        navigate(`/password-recovery/${location.search}`);
        setState("default");
      } else {
        redirectToEmailVerification();
      }
    }
  }, [location]);

  return state === "pending" || state === "token" ? (
    <PreloaderContainer>
      <Preloader />
    </PreloaderContainer>
  ) : (
    <div>
      <SEO />
      <MainscreenSection />
      <Ratings />
      <EnergyCalculatorSection isScroll={isCalculate} forcePromoCode={false} />
      <WhyStromee isScroll={isHome} />
      <KeyMessages />
      <Testimonial />
      <MobileAppSection />
      <Mentions />
      <Carousel isScroll={isHints} />
      <SeoText />
      <Footer isScroll={isContact} />
    </div>
  );
};

export default Index;
